import React from 'react'
import {Link} from 'react-router-dom'
import logo from '../assets/img/iTax-Solutions.svg'

class Layout extends React.Component {
  render(){
    return(
      <>
        <header>
          <div className='_grid'>
            <div className='_column'>
              <Link to="/" className='_logo'>
                <img src={logo} alt="iTax Solutions" />
              </Link>
              <a href="/#contact" className='_button' id="contact-button">Contact Us</a>
            </div>
          </div>
        </header>
        {this.props.children}
        <footer>
          <div className='_grid'>
            <div className='_column'>
              <p style={{textAlign:'center'}}><small>Disclaimer: Everything in this website including attachments relating to the official business of iTax Solutions (Pty) Ltd. It is confidential, legally privileged and protected by law. iTax Solutions (Pty) Ltd does not own or endorse any other content. Views and opinions are of the sender unless clearly stated as being that of iTax Solutions (Pty) Ltd. The person addressed in the e-mail is the only authorised recipient. Please notify the sender immediately if it has unintentionally reached you. Please do not read it, disclose or use the content in any way. iTax Solutions (Pty) Ltd cannot assure that the integrity of this communication has been maintained or that it is free of errors, virus, interception or interference.</small></p>
            </div>
          </div>
        </footer>
      </>
    )
  }
}

export default Layout