import email from '../assets/img/email.svg'
import phone from '../assets/img/phone.svg'
import facebook from '../assets/img/facebook.svg'
import linkedin from '../assets/img/linkedin.svg'

const ContactDetails = () => {

  return(
    <section className='_section-contact-us' id="contact">
      <div className="_grid">
        <div className="_column">
          <div className="_panel">
            <h2 className="_heading-1">OFFICE HOURS</h2>
            <p><strong>Mon-Thurs:</strong> 08h00 – 16h30 and <strong>Fri:</strong> 08h00 – 15h00</p>
            <div className="_contact-details">
              <a href="mailto:devon@itaxsol.co.za" className="_item">
                <img src={email} className="_icon" alt="Email" />
                <span>devon@itaxsol.co.za</span>
              </a>
              <a href="tel:+27 21 200 0873" className="_item">
                <img src={phone} className="_icon" alt="Phone" />
                <span>+27 21 200 0873</span>
              </a>
              <a href="https://www.facebook.com/iTaxSolutionsSA" className="_item" target="_blank" rel="noreferrer">
                <img src={facebook} className="_icon" alt="Facebook" />
                <span>@iTaxSolutionsSA</span>
              </a>
              <a href="https://www.linkedin.com/company/90793505" className="_item">
                <img src={linkedin} className="_icon" alt="LinkedIn" />
                <span>@iTax Solutions</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      
    </section>
  )
}

export default ContactDetails