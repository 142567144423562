import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'
import PageTracker from './PageTracker'

import Home from './components/Home'
import Thankyou from './components/Thankyou'
import NotFoundPage from './components/NotFoundPage'

class App extends React.Component {
  render(){
    return(
      <BrowserRouter>
        <PageTracker />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    )
  }
}

export default App