import React from 'react'

class SecondSection extends React.Component {
  render(){
    return(        
      <section className='_section'>
        <div className='_grid'>
          <div className='_column'>
            <div className='_panel'>
              <h1 className='_heading-1'>PARTNER WITH iTAX SOLUTIONS & IDENTIFY YOUR TAX POTENTIAL</h1>
              <p style={{marginBottom: '0'}}>Welcome to iTax Solutions, VAT saving specialists for South African companies. We are committed to service excellence and our team of experienced professionals is dedicated to assist clients to achieve optimal VAT efficiency.</p>
            </div>
          </div>
        </div>
      </section>        
    )
  }
}

export default SecondSection