import React from 'react'
import banner from '../assets/img/itax-solutions-banner@2x.jpg'
import bannerMobile from '../assets/img/itax-solutions-mobile-banner@2x.jpg'

class TopBannerSection extends React.Component {
  render(){
    return(
      <section className='_section-top-banner'>
        <img src={window.innerWidth > 768 ? banner : bannerMobile} alt="iTax Solutions" />
      </section>
    )
  }
}

export default TopBannerSection