import React from 'react'
import Layout from './Layout'
import TopBannerSection from './TopBannerSection'

class Thankyou extends React.Component {
  render(){
    return(
      <Layout>
        {/* <TopBannerSection /> */}
        <section className='_section'></section>
        <section className='_section'>
          <div className='_grid'>
            <div className='_column'>
              <h1 className='_heading-1'><span className='_orange'>MESSAGE SENT</span></h1>
              <p>Thank you for contacting us. A consultant will be in touch with you shortly.</p>
              <a href="/" className='_button'>Go To Home Page</a><br /><br />
            </div>
          </div>
        </section>    
      </Layout>
    )
  }
}

export default Thankyou